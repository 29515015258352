.car-card {
    .price {
        font-size: 24px;
        color: black;
    }
    
    .monthly {
        background-color: #63C089;
        color: black;
    }
}