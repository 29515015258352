@import "assets/styles/variables";
@import "assets/styles/shared";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-image-gallery/styles/scss/image-gallery.scss";

// .Mui-focused, .MuiAutocomplete-hasClearIcon {
//   color: var(--primary-color) !important;

//   .MuiOutlinedInput-notchedOutline {
//     border-color: var(--primary-color) !important;
//   }  

//   .MuiInputLabel-root {
//     color: var(--primary-color) !important;
//   }
// }

.header-gradient {
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(65, 65, 65, 1) 100%);
}

.primary-color {
    color: var(--primary-color);
}

// scroll
* {
    scrollbar-color: #00ce7c;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    
    ::-webkit-scrollbar-track {
        background: #E0E0E0;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: #00ce7c;
        border: 3px solid #00ce7c;
    }
}
