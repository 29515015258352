#home-section {
    #brand-selector {
        label.Mui-focused {
            color: #00ce7c;
        }

        .MuiOutlinedInput-root {
            border-radius: 24px;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: #00ce7c;

            :hover {
                border-color: #00ce7c;
            }
        }
    }
}
