body {
  margin: 0;
  padding: 0;
  min-width: 320px;

}

* {
    font-family: 'Poppins-Regular';
}
