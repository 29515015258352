.phoneInput {
    .react-international-phone-input {
        width: 100%;
        height: 50px;
        border-top-right-radius: 99px !important;
        border-bottom-right-radius: 99px !important;
        font-family: 'Poppins-Regular';
        font-size: 1rem;
        line-height: 1.5rem;
    }
    
    .react-international-phone-country-selector-button {
        height: 50px;
        border-top-left-radius: 99px !important;
        border-bottom-left-radius: 99px !important;
        padding: 4px;
    }

    .react-international-phone-country-selector-button__button-content {
        margin-left: 10px;
    }
}

#search-brand,
#search-model {
    color: white !important;

    ::placeholder {
        color: white;
    }
}

#date-selector,
#hour-selector,
#state-selector,
#city-selector {
    label.Mui-focused {
        color: #00ce7c;
    }

    .MuiOutlinedInput-root {
        border-radius: 99px;
        padding: 0px 20px;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: #e5e7eb;

        :hover {
            border-color: #e5e7eb;
        }
    }
}
