.car-detail {
    .image-gallery {
        width: 100%;
        height: auto;
    }

    .image-gallery-slide img {
        width: 100%;
        height: auto;
        max-height: 80vh;
        object-fit: cover;
        overflow: hidden;
        object-position: center center;
    }

    .fullscreen .image-gallery-slide img {
        max-height: 100vh;
    }

    .price {
        color: black;
    }

    .certificate {
        color: var(--error-color);
    }

    .monthly {
        background-color: #63C089;
        color: black;
    }

    .status {
        background-color: black;
        color: #63C089;
    }

    .error-label {
        color: var(--error-color);
    }
}