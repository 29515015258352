
.content ul,
.content  ol,
.content  p,
/* .content  h1, */
.content  h2,
.content  h3,
.content  h4,
.content div,
.content b,
.content a,
.content strong
{
    all:revert;
}


.content strong{
    font-family: 'Poppins-Bold';
}