#services {
    #services-header {
        padding: 20px;
        padding-bottom: 80px;

        background-color: var(--highlighted-color);
    }

    .services-content {
        padding: 20px;
        padding-top: 0px;

        .services-card {
            width: 100%;

            margin-top: -100px;

            .card-title {
                font-size: 26px;
            }

            .card-subtitle {
                font-size: 12px;
            }
        }

        .services-list {
            width: 100%;

            .service-item {
                padding: 4px 8px;
                border-radius: 16px;
                border: 2px solid transparent;
                cursor: pointer;
            }

            .service-item.active {
                border-color: var(--highlighted-color);
            }

            .list-image-container {
                display: table;
                width: 50px;

                .list-image {
                    display: table-cell;
                    vertical-align: middle;
                }
            }

            .list-info {
                padding-left: 12px;

                .list-title {
                    font-weight: bold;
                    font-size: 14px;
                }

                .list-subtitle {
                    font-size: 12px;
                }

                .list-note {
                    font-size: 12px;
                    color: var(--highlighted-color);
                }
            }

            .list-divider {
                margin-top: 6px;
                margin-bottom: 6px;
            }
        }

        @media (min-width: 420px) {
            .services-card {
                width: 380px;

                .card-title {
                    font-size: 28px;
                }

                .card-subtitle {
                    font-size: 14px;
                }
            }

            .services-list {
                width: 380px;
            }
        }

        @media (min-width: 768px) {
            .services-card {
                width: 520px;

                .card-title {
                    font-size: 30px;
                }

                .card-subtitle {
                    font-size: 16px;
                }
            }

            .services-list {
                width: 520px;

                .list-info {
                    .list-title {
                        font-size: 16px;
                    }

                    .list-subtitle {
                        font-size: 14px;
                    }

                    .list-note {
                        font-size: 14px;
                    }
                }
            }
        }

        .service-button {
            width: 300px;

            margin-top: 10px;
            margin-bottom: 10px;
            border-radius: 8px;

            // background-color: var(--highlighted-color);

            // &.back-button {
            //     background-color: var(--error-color);
            // }
        }

        .error-label {
            color: var(--error-color);
        }

        .date-active {
            background-color: var(--highlighted-color);
        }

        .date-title {
            font-size: 40px;
            color: var(--highlighted-color);
        }

        .date-subtitle {
            font-size: 16px;
            color: var(--primary-color);
        }
    }
}