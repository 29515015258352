#consign-car {
    #consign-car-header {
        padding: 20px;
        background-color: var(--highlighted-color);
    }

    .consign-car-content {
        padding: 20px;
        padding-top: 0px;

        .consign-car-list {
            width: 100%;
        }

        @media (min-width: 768px) {
            .consign-car-list {
                width: 100%;
                max-width: 768px;
            }
        }

        .consign-car-button {
            width: 300px;

            margin-top: 10px;
            margin-bottom: 10px;
            border-radius: 8px;

            background-color: var(--highlighted-color);

            &.back-button {
                background-color: var(--error-color);
            }
        }
    }

    .error-label {
        color: var(--error-color);
    }

    .succes-text {
        color: var(--highlighted-color);
    }

    .MuiSelect-select {
        padding: 8px 4px;
    }
}