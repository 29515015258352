.car-cover {
    position: relative;

    .car-status {
        position: absolute;
        bottom: 10px;
        right: 10px;

        background-color: black;
        color: #63C089;
    }
}